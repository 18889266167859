import { FeaturedProducts } from '@src/modules/home/featured';
import { HeroImage } from '@src/modules/home/hero-image';
import { Layout } from '@src/modules/shared/components/layout';
import { SeoTags } from '@src/modules/shared/components/seo-tags';
import type { ReactElement } from 'react';

export default function HomePage(): ReactElement {
	return (
		<Layout>
			<SeoTags />
			<HeroImage />
			<FeaturedProducts />
		</Layout>
	);
}

import { useFeaturedProducts } from '@src/lib/medusa/hooks/use-featured-products';
import { ProductPreview } from '@src/modules/products/preview';
import { Carousel } from '@src/modules/shared/components/carousel';
import { Skeleton } from '@src/modules/shared/components/skeleton';
import { IconArrowRight } from '@tabler/icons-react';
import Link from 'next/link';
import type { ReactElement } from 'react';
import { Fragment } from 'react';

import styles from './index.module.scss';

export function FeaturedProducts(): ReactElement {
	const { data: products = [], fetchStatus } = useFeaturedProducts();

	return (
		<div className="bg-gray-100">
			<div className="container flex flex-col gap-3 py-12">
				<div className="mb-4">
					<p>
						Welcome to the North America Catalog Store! This is where you can download a free copy of our respective
						brand catalogs. If you would like to purchase a printed hard copy, simply click your preferred catalog, add
						the item to your cart, and follow the checkout instructions.
					</p>
					<p>
						Please check back often as we are constantly updating and adding to our catalog offering in new
						applications, cross references, and new products in general.
					</p>
				</div>

				<div>
					<h2 className="text-center text-lg">Latest Filter Catalogs</h2>
					<div className="-mt-2 block text-center text-sm font-normal">
						<Link href="/products" className="text-xs no-underline">
							<span>Explore all products</span>
							<IconArrowRight />
						</Link>
					</div>
					<div className="my-6">
						{fetchStatus === 'fetching' && (
							<div className="flex w-full overflow-hidden">
								{[...Array(3).keys()].map((i) => {
									return <Skeleton key={i} className="aspect-[4/3] w-full shrink-0 basis-full p-2 sm:basis-1/3" />;
								})}
							</div>
						)}
						{fetchStatus !== 'fetching' && (
							<Fragment>
								{products.length < 1 && <div>There are no featured items at this time.</div>}
								{products.length > 0 && (
									<div className="w-full px-4 ">
										<Carousel
											slides={products.map((product) => (
												<div className={styles.slide} key={product.id}>
													<ProductPreview product={product} />
												</div>
											))}
											options={{
												loop: true,
												align: 'center',
												containScroll: 'keepSnaps',
											}}
										/>
									</div>
								)}
							</Fragment>
						)}
					</div>
				</div>

				<div className="text-center">
					<p>We thank you for choosing our products and being a valued customer.</p>{' '}
					<p>
						Please{' '}
						<a
							href="https://www.mann-hummel.com/en/contact.html"
							target="_blank"
							rel="noreferrer"
							className="underline">
							contact us
						</a>{' '}
						if you have any questions or concerns.
					</p>
				</div>
			</div>
		</div>
	);
}

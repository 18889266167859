import type { ReactElement } from 'react';

export function HeroImage(): ReactElement {
	return (
		<div
			className="relative h-[40vh] bg-cover bg-center md:h-[70vh]"
			style={{
				backgroundImage: 'url(/images/mann-hummel-catalogs.jpg)',
			}}>
			<div className="absolute inset-0 bg-black/20" />
			<div className="absolute bottom-6 left-6 z-10 text-white shadow-black text-shadow-lg md:bottom-16 md:left-16">
				{/*<span className="block text-3xl uppercase md:text-5xl">Leadership</span>*/}
				{/*<span className="block text-sm md:text-lg">in filtration</span>*/}
			</div>
		</div>
	);
}

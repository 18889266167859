import clsx from 'clsx';
import type { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import type { ReactElement } from 'react';
import React, { Fragment, useCallback, useEffect } from 'react';

import styles from './index.module.scss';

interface CarouselProps {
	index?: number;
	showButtons?: boolean;
	slides: ReactElement[];
	options: Partial<EmblaOptionsType>;
	classNames?: {
		wrapper?: string;
		viewport?: string;
		container?: string;
		slide?: string;
	};
}

export const Carousel = ({
	index, //
	slides,
	options,
	classNames,
	showButtons = false,
}: CarouselProps): ReactElement => {
	const [emblaRef, emblaApi] = useEmblaCarousel(options);

	const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
	const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

	useEffect(() => {
		emblaApi?.scrollTo(index || 0);
	}, [emblaApi, index]);

	return (
		<div className={classNames?.wrapper || styles.embla}>
			<div className={classNames?.viewport || styles.embla__viewport} ref={emblaRef}>
				<div className={classNames?.container || styles.embla__container}>
					{slides.map((slide, index) => (
						<div key={index} className={classNames?.slide || styles.embla__slide}>
							<Fragment>{slide}</Fragment>
						</div>
					))}
				</div>
			</div>
			{showButtons && (
				<div className="flex justify-center gap-x-3 mt-2">
					<button type="button" onClick={scrollPrev} title="Scroll Left">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 300 533.33"
							className={clsx('size-8 lg:size-12', styles.previousButton)}>
							<g data-name="Complete">
								<g data-name="F-Chevron">
									<path
										d="M266.67 33.33 33.33 266.67 266.67 500"
										fill="none"
										stroke="currentColor"
										strokeWidth={66.67}
										data-name="Left"
									/>
								</g>
							</g>
						</svg>
					</button>
					<button type="button" onClick={scrollNext} title="Scroll Right">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 300 533.33"
							className={clsx('size-8 lg:size-12', styles.nextButton)}>
							<g data-name="Complete">
								<g data-name="F-Chevron">
									<path
										d="m33.33 500 233.34-233.33L33.33 33.33"
										data-name="Left"
										fill="none"
										stroke="currentColor"
										strokeWidth={66.67}
									/>
								</g>
							</g>
						</svg>
					</button>
				</div>
			)}
		</div>
	);
};
